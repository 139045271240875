import { graphql } from 'gatsby';
import React from 'react';
import getLocaleBlocks from '../../lib/getLocaleBlocks';
import getLocaleString from '../../lib/getLocaleString';
import BlockContent from '../components/BlockContent';
import Layout from '../components/Layout';
import PageHeader from '../components/PageHeader';
import TestimonyCard from '../components/TestimonyCard';

const translations = [
  {
    locale: 'fr',
    url: '/temoignages/',
  },
];

export default function TestimonialsPage({ data, pageContext: { langKey } }) {
  const testimonials = React.useMemo(
    () =>
      data.testimonies.nodes.map((node) => {
        const address = getLocaleString(node.address, langKey);

        const blocks = getLocaleBlocks(node.body, langKey) || [];

        let text = <BlockContent blocks={blocks} />;

        return {
          fullName: node.fullName,
          address,
          text,
        };
      }),
    [data, langKey]
  );

  return (
    <Layout translations={translations}>
      <article className="space-y-48">
        <PageHeader>Testimonials</PageHeader>

        <div className="container">
          <ul className="columns-md gap-4 mx-auto">
            {testimonials.map((testimony, index) => (
              <TestimonyCard
                {...testimony}
                key={`testimony-${index}`}
                className="my-2"
              />
            ))}
          </ul>
        </div>
      </article>
    </Layout>
  );
}

export const query = graphql`
  query {
    testimonies: allSanityTestimony {
      nodes {
        body {
          _rawFr(resolveReferences: { maxDepth: 10 })
          _rawEn(resolveReferences: { maxDepth: 10 })
        }
        fullName
        address {
          en
          fr
        }
      }
    }
  }
`;
